import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter } from "react-router-dom";
import { I18nextProvider } from 'react-i18next';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { ApolloProvider } from '@apollo/react-hooks';
import App from "./App";
import i18n from './i18n';


const cache = new InMemoryCache();
const link = new HttpLink({
    uri: `${process.env.REACT_APP_CMS_URL}/graphql`
});
const client = new ApolloClient({
    cache,
    link
});

ReactDOM.render(
    <ApolloProvider client={client}>
        <I18nextProvider i18n={i18n}>
            <BrowserRouter basename={"/"}>
                <Route path="/" component={App} />
            </BrowserRouter>
        </I18nextProvider>
    </ApolloProvider>,
    document.getElementById("root")
);