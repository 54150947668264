import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { Context } from '../state/store'
const ReactMarkdown = require('react-markdown');

const About = (props) => {

    const { t } = props;
    const [state, dispatch] = useContext(Context);
    const isEnglish = state.language === 'en';
    const sectionInfo = state.sections.length ? state.sections.filter(section => section.section_type === "about")[0] : {};

    return (
        <React.Fragment>
            {sectionInfo ? <section
                id="about"
                className="about-area ptb-100 section-area bg-f9f9f9"
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <div className="section-title">
                                <span>{isEnglish ? sectionInfo.section_title_en : sectionInfo.section_title_sv}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row" >
                        

                        <div className="col-lg-6 col-md-12 col-sm-12 ">
                            <div className="row">

                                <div className="col-lg-12">
                                    {sectionInfo.section_summary_en &&sectionInfo.section_summary_sv?<div className="about-title">
                                        <h5><span> {isEnglish ? sectionInfo.section_summary_en : sectionInfo.section_summary_sv}</span></h5>
                                    </div>:null}

                                    <div className="our-mission">
                                        <ReactMarkdown source={isEnglish ? sectionInfo.section_description_en : sectionInfo.section_description_sv} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 offset-lg-7 about-image">
                            {sectionInfo.section_image ? <img src={`${process.env.NODE_ENV === 'production' ? sectionInfo.section_image.url : `${process.env.REACT_APP_CMS_URL}${sectionInfo.section_image.url}`}`} alt="about-img" /> : null}
                        </div>
                    </div>
                </div>
            </section> : null}

        </React.Fragment>
    );
}

export default withTranslation('about')(About);
