import React, { useContext, useState } from 'react';
import Icofont from 'react-icofont';
import { withTranslation } from 'react-i18next';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { Context } from '../state/store'


const Figures = (props) => {
    const { t } = props;
    const [state, dispatch] = useContext(Context);
    const [viewPortEntered, setViewPortEntered] = useState(false);

    const isEnglish = state.language === 'en';
    const sortedFigures = state.figures.sort((a, b) => {
        return a.figure_index - b.figure_index;

    });

    const getNumberFromTitle = (text) => {
        const postfix = text.substr(text.length - 1);
        const number = Number(text.substr(0, text.length - 1));

        return (
            <React.Fragment>
                <CountUp start={viewPortEntered ? null : 0} end={number}>
                    {({ countUpRef }) => {
                        return (
                            <VisibilitySensor
                                active={!viewPortEntered}
                                onChange={isVisible => {
                                    if (isVisible) {
                                        setViewPortEntered(true);
                                    }
                                }}
                                delayedCall
                            >
                                <span ref={countUpRef} ></span>
                            </VisibilitySensor>
                        );
                    }}
                </CountUp>
                <span>{postfix}</span>
            </React.Fragment>
        )
    };
    //Start Services Loop
    const figuresdata = sortedFigures.map((figure, index) => (
        <div className="col-lg-4 col-md-6 col-sm-6" key={index}>
            <div className="single-box no-center medium">
                <Icofont icon={figure.figure_icon} />
                <h3>{isEnglish ? figure.figure_text_en : figure.figure_text_sv}</h3>
                <p>{isEnglish ? figure.figure_description_en : figure.figure_description_sv}</p>
                <div className="bg-number">{getNumberFromTitle(figure.figure_title)}</div>
            </div>
        </div>
    ));
    //End Services Loop

    return (
        <React.Fragment>

            {figuresdata}

        </React.Fragment>
    );
}


export default withTranslation('figures')(Figures);
