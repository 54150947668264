import React, { useState, useContext, useEffect } from 'react';
import axios from "axios";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import ReCAPTCHA from "react-google-recaptcha";
import { Context } from '../state/store'
import { withTranslation } from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from "react-spinners/BounceLoader";
const recaptchaRef = React.createRef();
const formRef = React.createRef();

const ContactUs = (props) => {
    const { t } = props;
    const [state] = useContext(Context);
    const isEnglish = state.language === 'en';
    const sectionInfo = state.sections.length ? state.sections.filter(section => section.section_type === "contact")[0] : {};
    const initialFormState = {
        name: "",
        email: "",
        message: "",
        recaptcha: ""
    }

    const [formData, setFormData] = useState(initialFormState);
    const [success, setSuccess] = useState(false);
    const [failed, setFailed] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const isLoading = submitting;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const resetForm = () => {
        setFormData(initialFormState);
        formRef.current.resetValidationState(true);
    }

    const handleForm = async e => {
        setSubmitting(true);
        e.preventDefault();
        const token = await recaptchaRef.current.executeAsync();
        formData.recaptcha = token;
        axios
            .post(`${process.env.REACT_APP_BACKEND_URL}contact`, formData, {
                headers: { Accept: "application/json" }
            })
            .then(function (response) {
                resetForm();
                setSubmitting(false);
                setSuccess(true);
                setFailed(false);
            })
            .catch(function (error) {
                recaptchaRef.current.reset();
                setSubmitting(false);
                setSuccess(false);
                if (error.response.data && error.response.data.errors) {
                    error.response.data.errors.forEach((item) => {
                        if (formRef.current.inputs[item.field])
                            formRef.current.inputs[item.field].setError(item.messages.join('\n'));
                        else setFailed(true);
                    });
                } else {
                    setFailed(true);
                }
            });
    };
    const handleFields = e => setFormData({
        ...formData,
        [e.target.name]: e.target.value
    });


    return (
        <React.Fragment>
            {state.contacts.length ? <section id="contact" className="contact-area ptb-100 bg-f9f9f9">
                <div className="container">
                    <div className="section-title">
                        <span>{isEnglish ? sectionInfo.section_title_en : sectionInfo.section_title_sv}</span>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="contact-box">
                                <div className="icon">
                                    <i className="icofont-google-map" />
                                </div>
                                <h4>{t('address')}</h4>
                                <p><a target="_blank" href={`http://maps.google.com/?q=${state.contacts[0].contact_address}`}>{state.contacts[0].contact_address}</a></p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="contact-box">
                                <div className="icon">
                                    <i className="icofont-email" />
                                </div>
                                <h4>{t('email')}</h4>
                                <p><a href={`mailto:${state.contacts[0].contact_email}`}>{state.contacts[0].contact_email}</a></p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6" >
                            <div className="contact-box">
                                <div className="icon">
                                    <i className="icofont-phone" />
                                </div>
                                <h4>{t('phone')}</h4>
                                <p><a href={`tel:${state.contacts[0].contact_phone}`}>{state.contacts[0].contact_phone}</a></p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6" >
                            <div className="contact-box">
                                <div className="icon">
                                    <i className="icofont-clock-time" />
                                </div>
                                <h4>{t('openingHours')}</h4>
                                <p>{isEnglish ? state.contacts[0].contact_opening_hours_en : state.contacts[0].contact_opening_hours_sv}</p>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="get-in-touch">
                                {/* <p> This site is protected by reCAPTCHA and the Google
                     <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
    <a href="https://policies.google.com/terms"> Terms of Service</a> apply.
                                           </p> */}
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    size="invisible"
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                    badge="inline"
                                />

                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12" style={{ padding: 0 }}>
                                <LoadingOverlay
                                    active={isLoading}
                                    styles={{
                                        overlay: (base) => ({
                                            ...base,
                                            background: 'transparent'
                                        }),
                                        wrapper: {
                                            'backgroundColor': 'transparent'
                                        }
                                    }}
                                    spinner={<BounceLoader color={"#6abd4a"} size={150} />}
                                >
                                    <ValidationForm
                                        id="contactForm"
                                        ref={formRef}
                                        immediate={true}
                                        onSubmit={
                                            handleForm
                                        }
                                    >
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <TextInput
                                                        name="name"
                                                        id="name"
                                                        required
                                                        successMessage=""
                                                        errorMessage={t('nameError')}
                                                        className="form-control"
                                                        placeholder={t('name')}
                                                        autoComplete="off"
                                                        onChange={
                                                            handleFields
                                                        }
                                                    />
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <TextInput
                                                        name="email"
                                                        id="email"
                                                        type="email"
                                                        required
                                                        successMessage=""
                                                        errorMessage={t('emailError')}
                                                        className="form-control"
                                                        placeholder={t('email')}
                                                        autoComplete="off"
                                                        onChange={
                                                            handleFields
                                                        }
                                                    />
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>


                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="form-group">
                                                    <TextInput
                                                        name="message"
                                                        id="description"
                                                        multiline
                                                        placeholder={t('message')}
                                                        className="form-control"
                                                        required
                                                        successMessage=""
                                                        errorMessage={t('messageError')}
                                                        rows="5"
                                                        autoComplete="off"
                                                        onChange={
                                                            handleFields
                                                        }
                                                    />
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <button type="submit" className="btn btn-primary">{t('submit')}</button>
                                                <div id="msgSubmit" className="h3 text-center hidden"></div>
                                                <div className="clearfix"></div>
                                            </div>
                                            {success ? (
                                                <div className="col-lg-6 col-md-6 col-sm-6">
                                                    <div id="contact_send_status">
                                                        <h3 className="contactMsg">
                                                            {t('successMessage')}
                                                        </h3>
                                                    </div>
                                                </div>
                                            ) : null}
                                            {failed ? (
                                                <div className="col-lg-6 col-md-6 col-sm-6">
                                                    <div id="contact_send_status">
                                                        <h3 className="contactMsgFailure">
                                                            {t('errorMessage')}
                                                        </h3>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </ValidationForm>
                                </LoadingOverlay>

                            </div>
                        </div>


                    </div>
                </div>
            </section> : null}

        </React.Fragment>
    );

}

export default withTranslation('contact')(ContactUs);