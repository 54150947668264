import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { Context } from '../state/store'

const Why = (props) => {

    const { t } = props;
    const [state, dispatch] = useContext(Context);
    const isEnglish = state.language === 'en';
    const sectionInfo = state.sections.length ? state.sections.filter(section => section.section_type === "why")[0] : {};

    const whyUsData = state.whyUs.map((whyUs, index) => (
        <div className="col-lg-4 col-md-4 p-0" key={index}>
            <div className="single-choose">
                <i className={whyUs.icon} />
                <h5>{isEnglish ? whyUs.title_en : whyUs.title_sv}</h5>
            </div>
        </div>
    ));

    return (
        <React.Fragment>
            <section
                id="why"
                className="about-area ptb-100 bg-f9f9f9 section-area"
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <div className="section-title">
                                <span>{isEnglish ? sectionInfo.section_title_en : sectionInfo.section_title_sv}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                       
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="row">

                                <div className="col-lg-12">
                                   {sectionInfo.section_summary_en && sectionInfo.section_summary_sv?<div className="about-title">
                                        <h5><span> {isEnglish ? sectionInfo.section_summary_en : sectionInfo.section_summary_sv}</span></h5>
                                    </div>:null} 
                                    <div className="why-choose-us bg-f9f9f9-no-shadow">
                                        <div className="row m-0 bg-f9f9f9-no-shadow">
                                            {whyUsData}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 offset-lg-7 about-image">
                            {sectionInfo.section_image ? <img className="why-us-image" style={{marginTop:'50px'}} src={`${process.env.NODE_ENV === 'production' ? sectionInfo.section_image.url : `${process.env.REACT_APP_CMS_URL}${sectionInfo.section_image.url}`}`} alt="about-img" /> : null}
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default withTranslation('why')(Why);