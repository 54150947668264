import React, { useContext } from "react";
import OwlCarousel from "react-owl-carousel3";
import VisibilitySensor from "react-visibility-sensor";
import { withTranslation } from 'react-i18next';
import { Context } from '../../state/store'
import { isMobile } from 'react-device-detect';

const HomeSlides = (props) => {
    const [state] = useContext(Context);
    const isEnglish = state.language === 'en';


    const homeslidesdata = state.sliders.map(
        (homeslides, index) => (
            <React.Fragment key={index}>
                <div className="main-banner">
                    <img src={`${process.env.NODE_ENV === 'production' ? homeslides.slider_image.url : `${process.env.REACT_APP_CMS_URL}${homeslides.slider_image.url}`}`} />
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                                <VisibilitySensor partialVisibility> 
                                    {({ isVisible }) => (
                                        <div className="row hero-content" style={{flexDirection:'column',alignContent:'center'}}>
                                            <div className="col-lg-12 col-md-12 col-sm-12" >
                                                <h1
                                                    className={
                                                        isVisible
                                                            ? "animated fadeInUp slow opacityOne"
                                                            : "opacityZero"
                                                    }
                                                    style={{ color: homeslides.text_color,marginBottom:'7px' }}>
                                                    {isEnglish ? homeslides.slider_text_en : homeslides.slider_text_sv}
                                                </h1>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <h1
                                                    style={{ marginLeft: '11%' }}
                                                    className={
                                                        isVisible
                                                            ? "animated fadeInUp slow opacityOne"
                                                            : "opacityZero"
                                                    }
                                                    style={{ color: homeslides.text_color }}>
                                                    {isEnglish ? homeslides.slider_sub_text_en : homeslides.slider_sub_text_sv}
                                                </h1>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 offset-lg-2">
                                                {homeslides.slider_logo ? <img style={{position:'relative',width:`${isMobile?'30%':'70%'}`}} className={
                                                        isVisible
                                                            ? "animated fadeInUp slow opacityOne"
                                                            : "opacityZero"
                                                    }  src={`${process.env.NODE_ENV === 'production' ? homeslides.slider_logo.url : `${process.env.REACT_APP_CMS_URL}${homeslides.slider_logo.url}`}`} /> : null}
                                            </div>
                                        </div>
                                    )}
                                </VisibilitySensor>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    );

    return (
        <React.Fragment>
            <section id="home">
                {homeslidesdata.length ? <OwlCarousel
                    className="home-slides owl-theme"
                    items={1}
                    dots={true}
                    loop={true}
                    // rewind={true}
                    // animateOut={"fadeOut"}
                    // animateIn={"fadeIn"}
                    touchDrag={true}
                    autoplay={true}
                    autoplayTimeout={5000}
                    smartSpeed={900}>
                    {homeslidesdata}
                </OwlCarousel>
                    : null}

            </section>
        </React.Fragment>
    );
}

export default withTranslation('slider')(HomeSlides);
