import React, { useContext, useState, useEffect } from 'react';
import Icofont from "react-icofont";
import { Context } from '../state/store'
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AccordionSummary } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',

    },
    heading: {
        fontSize: theme.typography.pxToRem(17),
        fontFamily: 'MyWebFont',
        color: '#212529',
        '&:hover': {
            color: "#6abd4a",
        }

    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(17),
        color: theme.palette.text.secondary,
        fontFamily: 'MyWebFont',
        color: '#212529',
        '&:hover': {
            color: "#6abd4a",
        },
        textAlign: 'center'
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    details: {
        alignItems: 'center',
    },
    column: {
        flexBasis: '33.33%',

    },
    columnCenter: {
        flexBasis: '33.33%',
        textAlign: 'right'
    }
}));

const Service = (props) => {
    const { t, services } = props;
    const [state, dispatch] = useContext(Context);
    const isEnglish = state.language === 'en';
    const classes = useStyles();

    const [expanded, setExpanded] = useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const timeConvert = (n) => {
        const hourStr = isEnglish ? 'hour' : 'timme';
        const andStr = isEnglish ? 'and' : 'och';
        var num = n;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        if (rhours >= 1 && rminutes > 0)
            return `${rhours} ${hourStr} ${andStr} ${rminutes} min`;
        else if (rhours >= 1 && rminutes == 0)
            return `${rhours} ${hourStr}`;
        else
            return `${rminutes} min`;
    }

    const servicesData = services.map((service, index) => (
        <Accordion expanded={expanded === service.id} onChange={handleChange(service.id)} key={service.id}>
            <AccordionSummary
                className={classes.summary}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header">
                <div className={classes.column}>
                    <Typography className={classes.heading}>{isEnglish ? service.service_title_en : service.service_title_sv}</Typography>
                </div>


                <div className={classes.column}>
                    {service.service_price ?
                        <Typography className={classes.secondaryHeading}>
                            {service.service_price_from ? 'Fr' : ''} {service.service_price} kr
                       </Typography> :
                        <Typography className={classes.secondaryHeading}>
                        </Typography>}
                </div>

                <div className={classes.columnCenter}>
                    {service.service_booking_url ? <a href={service.service_booking_url} target="_blank">
                        <img style={{ borderRadius: '3px' }} src="https://foretag.bokadirekt.se/bokatid/BokaTid_Gron_MorkBakgrund_90px.gif" alt="Boka tid" border="0" />
                    </a> : null}
                </div>


            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                <Typography color="textSecondary">
                    {isEnglish ? service.service_description_en : service.service_description_sv}
                </Typography>
            </AccordionDetails>
        </Accordion>
    ));

    return (
        <div className="col-lg-12">
            {servicesData}
        </div>
    )
}

const Services = (props) => {
    const { t } = props;
    const [state, dispatch] = useContext(Context);
    const isEnglish = state.language === 'en';
    const sectionInfo = state.sections.length ? state.sections.filter(section => section.section_type === "services")[0] : {};

    const [serviceCategory, setServiceCategory] = useState("");

    useEffect(() => {
        if (state.serviceCategories && state.serviceCategories.length)
            setServiceCategory(state.serviceCategories[0].id)
    }, [state.serviceCategories]);



    const handleSelectServiceCategory = (serviceKey) => {
        setServiceCategory(serviceKey);
    }

    const getServiceCategoryItems = () => {
        return state.services.filter(service => service.geowash_service_category.id === serviceCategory);
    }

    const serviceCategoriesData = state.serviceCategories.map((services, index) => (
        <div className="col-lg-3 col-md-6 col-sm-6" key={index}>
            <div className={`single-services pointer ${serviceCategory === services.id ? "active" : ""}`} onClick={() => { handleSelectServiceCategory(services.id) }}>
                <Icofont icon={services.category_icon} />
                <h3>{isEnglish ? services.category_title_en : services.category_title_sv}</h3>
            </div>
        </div>
    ));

    return (
        <React.Fragment>
            <section id="services" className="services-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <div className="section-title">
                                <span>{isEnglish ? sectionInfo.section_title_en : sectionInfo.section_title_sv}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">{serviceCategoriesData}</div>
                    <div className="row">
                        <Service services={getServiceCategoryItems()} />
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default withTranslation('services')(Services);