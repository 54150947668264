import gql from "graphql-tag";


const GET_CONTACT_DATA = gql`
{
  geowashContacts{
    id
    contact_email
    contact_phone
    contact_address
    contact_map
    contact_opening_hours_en
    contact_opening_hours_sv    
  }
}`

const GET_SECTIONS_DATA = gql`
{
  geowashSections {
    id
    section_title_en
    section_title_sv
    section_summary_en
    section_summary_sv
    section_type
    section_image{
      id
      url
    }
    section_video_poster{
      id
      url
    }
    section_description_en
    section_description_sv
      }
}`


const GET_SOCIAL_MEDIA_DATA = gql`
{
  geowashSocialmedias{
        id
        socialmedia_type
        socialmedia_accountname
      }
  }`


const GET_FAQ_DATA = gql`
{
  geowashFaqs {
        id
        faq_question_en
        faq_question_sv
        faq_answer_en
        faq_answer_sv
      }
  }`

const GET_SERVICES_CATEGORIES_DATA = gql`
  {
    geowashServiceCategories{
      id
      category_title_en
      category_title_sv
      category_icon
    }
    }`


const GET_SERVICES_DATA = gql`
{
  geowashServices{
  id
  service_title_en
  service_title_sv
  service_description_en
  service_description_sv
  service_price
  service_price_from
  service_booking_url
  geowash_service_category{
    id
  }
}
  }`




const GET_SLIDER_DATA = gql`
{
  geowashSliders {
    id
    slider_text_en
    slider_text_sv
    slider_sub_text_en
    slider_sub_text_sv
    slider_index
    text_color
    slider_image {
      id
      url
    }
    slider_logo {
      id
      url
    }
  }
}`

const GET_FIGURES_DATA = gql`
{
  geowashFigures {
    id
    figure_title
    figure_text_en
    figure_text_sv
    figure_description_sv
    figure_description_en
    figure_index
    figure_icon
  }
}`

const GET_GALLERIES_DATA = gql`
{
  geowashGalleries {
    id
    gallery_type
    gallery_media {
      id
      name
      alternativeText
      formats
      caption
      ext
      url
      previewUrl
    }
  }
}`


const GET_QUOTES_DATA = gql`
{
  geowashMainQuotes{
    id
    quote_title_en
    quote_title_sv
    quote_summary_en
    quote_summary_sv
    quote_image{
      id
      url
      formats
    }
  }
}`


const GET_WHY_US_DATA = gql`
{
  geowashWhyuses{
    id
    title_en
    title_sv
    icon
  }
}`

const GET_OFFERS_DATA = gql`
{
  geowashOffers(
    sort: "offer_start_date:asc"
    where: {
      _where: {
        _or: [
          {
            offer_start_date_lte: "${new Date().toISOString()}"
            offer_end_date_gte: "${new Date().toISOString()}"
          }
          {
            offer_start_date_lte: "${new Date().toISOString()}"
            offer_end_date_null: true
          }
        ]
      }
    }
  ) {
    id
    offer_description_en
    offer_description_sv
    offer_start_date
    offer_end_date
    offer_icon
    offer_link
  }
}`

export default {
  GET_CONTACT_DATA,
  GET_SECTIONS_DATA,
  GET_SOCIAL_MEDIA_DATA,
  GET_FAQ_DATA,
  GET_SERVICES_CATEGORIES_DATA,
  GET_SERVICES_DATA,
  GET_GALLERIES_DATA,
  GET_FIGURES_DATA,
  GET_SLIDER_DATA,
  GET_QUOTES_DATA,
  GET_WHY_US_DATA,
  GET_OFFERS_DATA
}