import React, { useContext } from 'react';
import Icofont from 'react-icofont';
import { withTranslation } from 'react-i18next';
import { Accordion, AccordionItem } from "react-sanfona";
import { Context } from '../state/store'

const Scroll = require('react-scroll');
const scroller = Scroll.scroller;

const FAQ = (props) => {
    const { t } = props;
    const [state, dispatch] = useContext(Context);
    const isEnglish = state.language === 'en';
    const sectionInfo = state.sections.length ? state.sections.filter(section => section.section_type === "faq")[0] : {};

    const scrollToContact = () => {
        scroller.scrollTo('contact');
    }
    return (
        <React.Fragment>
            <section className="faq ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <div className="section-title">
                                <span>{isEnglish ? sectionInfo.section_title_en : sectionInfo.section_title_sv}</span>
                            </div>
                        </div>
                    </div>
                    <div className="faq-content">
                        <div className="row">
                            <div className="col-lg-8">
                                <Accordion
                                    rootTag="div"
                                    className="panel-group"
                                >
                                    {state.faqs && state.faqs.map(item => {
                                        return (
                                            <AccordionItem
                                                key={item.id}
                                                title={isEnglish ? item.faq_question_en : item.faq_question_sv}
                                                expanded={false}
                                                expandedClassName=""
                                                className="panel-title panel panel-default"
                                                titleTag="a"
                                                titleClassName=""
                                            >
                                                <div>
                                                    <div className="panel-body">
                                                        <p>{isEnglish ? item.faq_answer_en : item.faq_answer_sv}</p>
                                                    </div>
                                                </div>
                                            </AccordionItem>
                                        );
                                    })}
                                </Accordion>
                            </div>
                            <div className="col-lg-4">
                                <div className="content-box color-effect-1">
                                    <h3>{t('contentTitle')}</h3>

                                    <div className="box-icon-wrap box-icon-effect-1 box-icon-effect-1a">
                                        <div className="box-icon" onClick={scrollToContact}><Icofont icon="icofont-share-alt" /></div>
                                    </div>

                                    <p>{t('contentDescription')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default withTranslation('faq')(FAQ);
