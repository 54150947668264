import React, { useContext, useEffect } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-scroll";
import { Navbar, Nav, Container } from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import { Context } from '../state/store'
import { isMobile } from 'react-device-detect';
import Offers from "./Offers";

const NavBar = (props) => {
    const [state, dispatch] = useContext(Context);
    const lang = state.language;
    const { t } = props;


    const closeNavbar = () => {
        if (window.matchMedia("screen and (max-width: 991px)").matches) {
            document.getElementById("collaspe-btn").click();
        }
    }

    const changeLang = (lang) => {
        const { i18n } = props;
        dispatch({ type: 'SET_LANGUAGE', payload: lang })
        i18n.changeLanguage(lang);
    }

    return (
        <React.Fragment>

            <Navbar
                sticky="top"
                id="navbar"
                bg="light"
                expand="lg"
                className="navbar navbar-expand-lg navbar-light bg-light is-sticky sticky-top"
                collapseOnSelect={true}
            >
                <Offers />
                <Container style={{ marginTop: '25px' }}>

                    <Navbar.Brand >
                        <Link
                            to="home"
                            spy={true}
                            smooth={true}
                            offset={isMobile ? -450 : -105}
                            isDynamic={true}
                            duration={800}
                        >
                            <img src={props.Logo} alt="Logo" />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle
                        aria-controls="basic-navbar-nav"
                        id="collaspe-btn" />
                    <Navbar.Collapse id="basic-navbar-nav">

                        <Nav className="ml-auto">

                            <Nav.Item>
                                <Link
                                    activeClass="active"
                                    to="home"
                                    spy={true}
                                    smooth={true}
                                    offset={isMobile ? -450 : -105}
                                    isDynamic={true}
                                    duration={800}
                                    className="nav-link"
                                    onClick={closeNavbar}
                                >
                                    {t('home')}
                                </Link>
                            </Nav.Item>

                            <Nav.Item>
                                <Link
                                    activeClass="active"
                                    to="about"
                                    spy={true}
                                    smooth={true}
                                    offset={isMobile ? -450 : -105}
                                    isDynamic={true}
                                    duration={800}
                                    className="nav-link"
                                    onClick={closeNavbar}
                                >
                                    {t('about')}
                                </Link>
                            </Nav.Item>

                            <Nav.Item>
                                <Link
                                    activeClass="active"
                                    to="mission"
                                    spy={true}
                                    smooth={true}
                                    offset={isMobile ? -450 : -105}
                                    isDynamic={true}
                                    duration={800}
                                    className="nav-link"
                                    onClick={closeNavbar}
                                >
                                    {t('mission')}
                                </Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link
                                    activeClass="active"
                                    to="why"
                                    spy={true}
                                    smooth={true}
                                    offset={isMobile ? -450 : -105}
                                    isDynamic={true}
                                    duration={800}
                                    className="nav-link"
                                    onClick={closeNavbar}
                                >
                                    {t('why')}
                                </Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link
                                    activeClass="active"
                                    to="services"
                                    spy={true}
                                    smooth={true}
                                    isDynamic={true}
                                    offset={isMobile ? -450 : -105}
                                    duration={800}
                                    className="nav-link"
                                    onClick={closeNavbar}
                                >
                                    {t('services')}
                                </Link>
                            </Nav.Item>

                            <Nav.Item>
                                <Link
                                    activeClass="active"
                                    to="portfolio"
                                    spy={true}
                                    smooth={true}
                                    isDynamic={true}
                                    offset={isMobile ? -450 : -105}
                                    duration={800}
                                    className="nav-link"
                                    onClick={closeNavbar}
                                >
                                    {t('gallery')}
                                </Link>
                            </Nav.Item>

                            <Nav.Item>
                                <Link
                                    activeClass="active"
                                    to="faq"
                                    spy={true}
                                    smooth={true}
                                    isDynamic={true}
                                    offset={isMobile ? -450 : -105}
                                    duration={800}
                                    className="nav-link"
                                    onClick={closeNavbar}
                                >
                                    {t('faq')}
                                </Link>
                            </Nav.Item>

                            <Nav.Item>
                                <Link
                                    activeClass="active"
                                    to="contact"
                                    spy={true}
                                    smooth={true}
                                    isDynamic={true}
                                    offset={isMobile ? -450 : -105}
                                    duration={800}
                                    className="nav-link"
                                    onClick={closeNavbar}
                                >
                                    {t('contact')}
                                </Link>
                            </Nav.Item>
                            {lang === "en" ?
                                <Nav.Item>

                                    <Link
                                        activeclass="active"
                                        to=""
                                        spy={true}
                                        smooth={true}
                                        isDynamic={true}
                                        offset={isMobile ? -450 : -105}
                                        duration={800}
                                        className="nav-link"
                                        onClick={() => changeLang('sv')}
                                    >
                                        SV
                                        <img className="language-image" src={props.svImage} alt="swedish" />

                                    </Link>
                                </Nav.Item> :
                                <Nav.Item>

                                    <Link
                                        activeclass="active"
                                        to=""
                                        spy={true}
                                        smooth={true}
                                        offset={isMobile ? -450 : -105}
                                        isDynamic={true}
                                        duration={800}
                                        className="nav-link"
                                        onClick={() => changeLang('en')}
                                    >
                                        EN
                                        <img className="language-image" src={props.enImage} alt="english" />

                                    </Link>
                                </Nav.Item>}

                        </Nav>
                    </Navbar.Collapse>

                </Container>
                {/* <div className="parking-area">
                    <a href="https://www.bokadirekt.se/places/geowash-sweden-39387" target="_blank">
                        2 hr free parking in Entre Malmö
                        </a>
                </div> */}
            </Navbar>
        </React.Fragment>
    )
}

//Props Types
NavBar.propTypes = {
    Logo: PropTypes.string,
};

//Default Props
NavBar.defaultProps = {
    Logo: require('../assets/img/logo.png'),
    enImage: require("../assets/img/english.png"),
    svImage: require("../assets/img/sweden.png"),
};
export default withTranslation("menu")(NavBar);