import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { Context } from '../state/store'
import Icofont from 'react-icofont';
import OwlCarousel from "react-owl-carousel3";
import moment from 'moment'

const Offers = (props) => {

    const { t } = props;
    const [state, dispatch] = useContext(Context);
    const isEnglish = state.language === 'en';
    const transformOfferDate = (offerDate) => {
        let transformedDate = ``;
        if (offerDate)
            transformedDate = `${t('until')} ${moment(offerDate).format("D MMMM")}`;
        return transformedDate;
    }
    //Start Offers Loop
    const offersData = state.offers.map((offer, index) => (
        <div className="col-lg-12 col-md-12 col-sm-12" key={index}>
            <Icofont icon={offer.offer_icon} /><a href={offer.offer_link} target="_blank"><p>{isEnglish ? offer.offer_description_en : offer.offer_description_sv} {transformOfferDate(offer.offer_end_date)}</p></a>
        </div>
    ));
    //End Offers Loop
    const offersSliderOptions = {
        items: 1,
        loop: false,
        center: true,
        dots: false,
        rewind: true,
        autoplay: true,
        mouseDrag: false,
        autoplayHoverPause: false,
        responsiveClass: true,
        autoplayTimeout: 5000,
        smartSpeed: 900,
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 1,
            },
            768: {
                items: 1,
            },
            1200: {
                items: 1,
            }
        }
    };

    return (
        <React.Fragment>
            {offersData.length ?
                <section className="offers-area">
                    <OwlCarousel
                        className="owl-theme"
                        {...offersSliderOptions}>
                        {offersData}
                    </OwlCarousel>
                </section> : null}
        </React.Fragment>
    );
}

export default withTranslation('offers')(Offers);
