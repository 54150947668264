import React, { createContext, useReducer } from "react";
import Reducer from './reducer'
import i18n from '../i18n';


const initialState = {
    sections: [],
    services: [],
    figures: [],
    sliders: [],
    faqs: [],
    galleries: [],
    socialMedias: [],
    contacts: [],
    quotes: [],
    serviceCategories: [],
    whyUs: [],
    offers: [],
    language: i18n.language
};

const Store = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    return (
        <Context.Provider value={[state, dispatch]}>
            { children}
        </Context.Provider>
    )
};

export const Context = createContext(initialState);
export default Store;