import React, { useContext } from 'react';
import Icofont from "react-icofont";
import { withTranslation } from 'react-i18next';
import { Context } from '../state/store'

const SocialMedia = (props) => {

    const { t } = props;
    const [state, dispatch] = useContext(Context);

    const socialMediaData = state.socialMedias.map((socialMedia, index) => (
        <li key={socialMedia.id}><a href={`https://${socialMedia.socialmedia_type}.com/${socialMedia.socialmedia_accountname}`}><Icofont icon={`icofont-${socialMedia.socialmedia_type}`} /></a></li>

    ));

    return (
        <React.Fragment>
            <ul className="social-links">
                <li><p>{t('followUs')}</p></li>
                {socialMediaData}
            </ul>
        </React.Fragment>
    );
}

export default withTranslation('socialMedia')(SocialMedia);