import React, { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import LoadingOverlay from 'react-loading-overlay'
import NavBar from "../components/NavBar";
import BannerOne from "../components/banner/BannerOne";
import Welcome from "../components/Welcome";
import About from "../components/About";
import Mission from "../components/Mission";
import Why from "../components/Why";
import Portfolio from "../components/Portfolio";
import Services from "../components/Services";
import FAQ from "../components/FAQ";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer";
import ScrollUpBtn from "../components/ScrollUpBtn";
import { Context } from '../state/store'
import queries from '../data/queries'

import LoadingImage from '../assets/img/geowash-unscreen.gif';

const Home = () => {
  const [state, dispatch] = useContext(Context);
  const faqs = useQuery(queries.GET_FAQ_DATA);
  const sections = useQuery(queries.GET_SECTIONS_DATA);
  const serviceCategories = useQuery(queries.GET_SERVICES_CATEGORIES_DATA);
  const services = useQuery(queries.GET_SERVICES_DATA);
  const contacts = useQuery(queries.GET_CONTACT_DATA);
  const sliders = useQuery(queries.GET_SLIDER_DATA);
  const figures = useQuery(queries.GET_FIGURES_DATA);
  const galleries = useQuery(queries.GET_GALLERIES_DATA);
  const socialMedias = useQuery(queries.GET_SOCIAL_MEDIA_DATA);
  const quotes = useQuery(queries.GET_QUOTES_DATA);
  const whyUs = useQuery(queries.GET_WHY_US_DATA);
  const offers = useQuery(queries.GET_OFFERS_DATA);


  useEffect(() => {
    if (!faqs.loading)
      dispatch({ type: 'SET_FAQS', payload: faqs.data.geowashFaqs });
    if (!sections.loading)
      dispatch({ type: 'SET_SECTIONS', payload: sections.data.geowashSections });
    if (!serviceCategories.loading)
      dispatch({ type: 'SET_SERVICE_CATEGORIES', payload: serviceCategories.data.geowashServiceCategories });
    if (!services.loading)
      dispatch({ type: 'SET_SERVICES', payload: services.data.geowashServices });
    if (!contacts.loading)
      dispatch({ type: 'SET_CONTACTS', payload: contacts.data.geowashContacts });
    if (!socialMedias.loading)
      dispatch({ type: 'SET_SOCIALMEDIAS', payload: socialMedias.data.geowashSocialmedias });
    if (!galleries.loading)
      dispatch({ type: 'SET_GALLERIES', payload: galleries.data.geowashGalleries });
    if (!figures.loading)
      dispatch({ type: 'SET_FIGURES', payload: figures.data.geowashFigures });
    if (!sliders.loading)
      dispatch({ type: 'SET_SLIDERS', payload: sliders.data.geowashSliders });
    if (!quotes.loading)
      dispatch({ type: 'SET_QUOTES', payload: quotes.data.geowashMainQuotes });
    if (!whyUs.loading)
      dispatch({ type: 'SET_WHY_US', payload: whyUs.data.geowashWhyuses });
    if (!offers.loading)
      dispatch({ type: 'SET_OFFERS', payload: offers.data.geowashOffers });

  }, [faqs.loading, sections.loading, services.loading, contacts.loading, socialMedias.loading,
  sliders.loading, figures.loading, galleries.loading, quotes.loading, serviceCategories.loading, whyUs.loading, offers.loading]);

  const isLoading = faqs.loading || sections.loading || services.loading || contacts.loading || socialMedias.loading
    || sliders.loading || figures.loading || galleries.loading || quotes.loading || serviceCategories.loading || whyUs.loading || offers.loading;

  return (
    <React.Fragment>
      <LoadingOverlay
        active={isLoading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: 'white'
          }),
          wrapper: {
            'backgroundColor': 'white'
          }
        }}
        spinner={<img src={LoadingImage} />}
      >
        <NavBar />
        <BannerOne />
        <Welcome />
        <About />
        <Mission />
        <Why />
        <Services />
        <Portfolio />
        <FAQ />
        <ContactUs />
        <Footer />
        <ScrollUpBtn />
      </LoadingOverlay>
    </React.Fragment>
  );
}

export default Home;