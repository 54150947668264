import React, { useContext } from "react";
import Figures from './Figures';
import { Context } from '../state/store'
import { withTranslation } from 'react-i18next';


const Welcome = (props) => {
    const { t } = props;
    const [state, dispatch] = useContext(Context);
    const isEnglish = state.language === 'en';
    const sectionInfo = state.sections.length ? state.sections.filter(section => section.section_type === "welcome")[0] : {};


    const quotesData = state.quotes.map((quote, index) => (
        <div className="col-lg-4 col-md-6 col-sm-6" key={index}>
            <div className="single-box medium main">
            <img src={`${process.env.NODE_ENV === 'production' ? quote.quote_image.formats.thumbnail.url : `${process.env.REACT_APP_CMS_URL}${quote.quote_image.formats.thumbnail.url}`}`} alt={quote.quote_image.name} />
                <h3>{isEnglish ? quote.quote_title_en : quote.quote_title_sv}</h3>
                <p>{isEnglish ? quote.quote_summary_en : quote.quote_summary_sv}</p>
            </div>
        </div>
    ));

    return (
        <React.Fragment>
            <section className="welcome-area ptb-100">
                <div className="container">
                    <div className="section-title">
                        <span>{isEnglish ? sectionInfo.section_title_en : sectionInfo.section_title_sv}</span>
                    </div>

                    <div className="row">
                        {quotesData}
                    </div>
                    <div className="row">
                    <Figures />
                    </div>

                </div>
            </section>
        </React.Fragment>
    );

}

export default withTranslation('welcome')(Welcome);