import React, { useContext } from "react";
import { withTranslation } from 'react-i18next';
import { Context } from '../state/store'
import OwlCarousel from "react-owl-carousel3";

const Portfolio = (props) => {
    const { t } = props;
    const [state] = useContext(Context);
    const isEnglish = state.language === 'en';
    const sectionInfo = state.sections.length ? state.sections.filter(section => section.section_type === "gallery")[0] : {};
    const images = state.galleries.length ? state.galleries.filter(gallery => gallery.gallery_type === "images")[0].gallery_media : [];
    const videos = state.galleries.length ? state.galleries.filter(gallery => gallery.gallery_type === "videos")[0].gallery_media : [];


    const imagesData = images.map(
        (image, index) => (
            <div className="col-lg-12 col-md-12 col-sm-12" key={index}>
                <div className="single-team grow">

                    <img src={`${process.env.NODE_ENV === 'production' ? image.url : `${process.env.REACT_APP_CMS_URL}${image.url}`}`} alt={image.name} />
                </div>
            </div>
        )
    );



    const videosData = videos.map(
        (video, index) => (
            <div className="col-lg-12 col-md-12 col-sm-12" key={index}>
                <div className="single-team">
                    <div className="item-video" data-merge={index}>
                        <video controls={true} src={`${process.env.NODE_ENV === 'production' ? video.url : `${process.env.REACT_APP_CMS_URL}${video.url}`}`} />
                    </div>
                </div>
            </div>
        )
    );


    const imageSliderOptions = {
        margin:10,
        loop: true,
        dots: true,
        autoplay: true,
        nav: false,
        mouseDrag: true,
        autoplayHoverPause: true,
        responsiveClass: true,
        autoplayTimeout: 5000,
        smartSpeed: 900,
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 2,
            },
            768: {
                items: 3,
            },
            1200: {
                items: 4,
            }
        }
    };

    const videoSliderOptions = {
        margin:50,
        loop: false,
        dots: true,
        autoplay: true,
        nav: false,
        mouseDrag: true,
        autoplayHoverPause: true,
        responsiveClass: true,
        autoplayTimeout: 5000,
        smartSpeed: 900,
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 2,
            },
            768: {
                items: 3,
            },
            1200: {
                items: 4,
            }
        }
    };



    return (
        <React.Fragment>
            <section id="portfolio" className="portfolio-area ptb-100 bg-f9f9f9">
                <div className="container">
                    <div className="section-title">
                        <span>{isEnglish ? sectionInfo.section_title_en : sectionInfo.section_title_sv}</span>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">

                            {imagesData.length ?
                                <OwlCarousel
                                    className="team-slides owl-theme"
                                    {...imageSliderOptions}
                                    navText={[
                                        "<i class='icon icon-Arrow'></i>",
                                        "<i class='icon icon-Arrow'></i>"
                                    ]}>
                                    {imagesData}
                                </OwlCarousel>
                                : null}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12">
                            {videosData.length ? <OwlCarousel
                                className="team-slides owl-theme"
                                {...videoSliderOptions}>
                                {videosData}
                            </OwlCarousel>
                                : null}
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default withTranslation('portfolio')(Portfolio);