import React from 'react';
import { Route, Switch } from 'react-router-dom';

//Package CSS & JS
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.min.js";
import "animate.css/animate.min.css";

//Template SCSS & CSS Style
import "./assets/css/style.scss";
import "./assets/css/responsive.scss";


//Import Component
import Home from "./pages/Home";

//Import global store
import Store from './state/store'


const App = () => {
    return (
        <Store >
            <div className="App">
                <div>
                    <Switch >
                        <Route
                            path="/"
                            component={Home}
                        />
                    </Switch>
                </div>
            </div>
        </Store>
    );
}
export default App;