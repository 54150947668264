import React from "react";
import { withTranslation } from 'react-i18next';
import SocialMedia from './SocialMedia';
const Footer = (props) => {
    const { t } = props;
    return (
        <React.Fragment>
            <footer className="footer-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <p>{(new Date().getFullYear())} {t('copyRight')}</p>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <SocialMedia />
                        </div>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    );
}
export default withTranslation('footer')(Footer);