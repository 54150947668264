const Reducer = (state, action) => {
    switch (action.type) {
        case 'SET_SECTIONS':
            return {
                ...state,
                sections: action.payload
            };
        case 'SET_SERVICE_CATEGORIES':
            return {
                ...state,
                serviceCategories: action.payload
            };
        case 'SET_SERVICES':
            return {
                ...state,
                services: action.payload
            };
        case 'SET_FIGURES':
            return {
                ...state,
                figures: action.payload
            };
        case 'SET_SLIDERS':
            return {
                ...state,
                sliders: action.payload
            };
        case 'SET_FAQS':
            return {
                ...state,
                faqs: action.payload
            };
        case 'SET_GALLERIES':
            return {
                ...state,
                galleries: action.payload
            };
        case 'SET_SOCIALMEDIAS':
            return {
                ...state,
                socialMedias: action.payload
            };
        case 'SET_CONTACTS':
            return {
                ...state,
                contacts: action.payload
            };
        case 'SET_QUOTES':
            return {
                ...state,
                quotes: action.payload
            };
        case 'SET_WHY_US':
            return {
                ...state,
                whyUs: action.payload
            };
        case 'SET_LANGUAGE':
            return {
                ...state,
                language: action.payload
            };
        case 'SET_OFFERS':
            return {
                ...state,
                offers: action.payload
            };
        default:
            return state;
    }
};

export default Reducer;